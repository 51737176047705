<template>
  <!-- 号码池页面 -->
  <div class="number">
    <!-- 操作区域 -->
    <el-row>
      <el-col :span="12">
        <div class="title-name">
          <span>号码列表</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="title-button">
          <div class="ml-10">
            <el-button
              icon="el-icon-search"
              class="button-el"
              size="medium"
              @click="theQueryDialog = true"
              >查询
            </el-button>
          </div>

          <div class="ml-10">
            <el-button
              class="button-el"
              size="medium"
              @click="batchDelete"
              >批量删除
            </el-button>
          </div>
          <div class="ml-10">
            <el-button
            
              class="button-el"
              size="medium"
              @click="Add"
              >增加
            </el-button>
          </div>
          <div class="ml-10">
            <el-button
            
              class="button-el"
              size="medium"
              @click="AddAll"
              >批量增加
            </el-button>
          </div>
          <div class="ml-10">
            <el-tooltip content="刷新列表" placement="top" effect="light">
              <div
                class="font-refresh-out flex-nowrap-center-center"
                @click="getNumberList()"
              >
                <icon-font
                  v-if="loading == false"
                  type="icon-shuaxin"
                  class="font-refresh"
                />
                <i v-else class="el-icon-loading loding-color"></i>
              </div>
            </el-tooltip>
          </div>
          <!-- new -->
        </div>
      </el-col>
    </el-row>
    <!-- 数据列表 -->
    <el-row>
      <el-col :span="24">
        <div>
          <el-table
            v-loading="loading"
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            max-height="700"
            size="mini"
            :row-style="{ height: '50px' }"
            :cell-style="{ padding: '0px' }"
            :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"></el-table-column>

            <el-table-column label="用户ID" fixed width="130px">
              <template #default="scope"
                >{{ scope.row.uid == null ? "-" : scope.row.uid }}
              </template>
            </el-table-column>
            <el-table-column label="用户姓名">
              <template #default="scope"
                >{{ scope.row.nickname == null ? "-" : scope.row.nickname }}
              </template>
            </el-table-column>
            <el-table-column label="主叫号码">
              <template #default="scope"
                >{{ scope.row.telA == null ? "-" : scope.row.telA }}
              </template>
            </el-table-column>
            <el-table-column label="小号">
              <template #default="scope"
                >{{
                  scope.row.telX == null ? "-" : scope.row.telX
                }}
              </template>
            </el-table-column>
            <el-table-column label="创建时间">
              <template #default="scope"
                >{{
                  scope.row.createTime == null ? "-" : scope.row.createTime
                }}
              </template>
            </el-table-column>
            
            <!-- <el-table-column label="操作" fixed="right" width="180">
              <template #default="scope">
              
                <div class="flex-nowrap-space-around">
                  <span class="button-font-color" @click="bindFun(scope.row)">
                    <icon-font type="icon-bangding"/>
                    绑定
                  </span>
                  <span class="button-font-color" @click="modify(scope.row)">
                    <icon-font type="icon-bianji"/>
                    编辑
                  </span>
                  <span class="button-font-color" @click="confirmEvent(scope.row.id)">
                    <icon-font type="icon-shanchu"/>
                    删除
                  </span>
                </div>
              </template>
            </el-table-column> -->
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- 页码 -->
    <div class="pager">
      <div>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[20, 30, 40, 50, 100, 500]"
          :page-size="searchForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 筛选查询弹窗 -->
    <div>
      <el-dialog
        title="查询"
        v-model="theQueryDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        width="30%"
        @close="clearInputData"
      >
        <div class="">
          <el-form
            label-position="left"
            label-width="100px"
            size="small"
            :model="searchForm"
            ref="upform"
          >
            <div class="form-out-box p-0-30">
              <el-form-item label="分配用户">
                <el-select
                  v-model="searchForm.uid"
                  placeholder="请选择"
                  style="width: 100%"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in user_list"
                    :key="index"
                    :label="`${item.nickname || ''}--${
                      item.company || ''
                    }--(Id:${item.uid})`"
                    :value="item.uid"
                  >
                    <div class="flex-nowrap-space-between">
                      <div :class="item.status == 1 ? 'no-red' : ''">
                        <span>{{ item.nickname }}--</span>
                        <span>{{ item.company }}--</span>
                        <span class="ml-5">(Id:{{ item.uid }})</span>
                      </div>
                      <div v-if="item.status == 1" class="no-red">禁</div>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="主叫号码">
                <el-input
                  v-model.trim="searchForm.telA"
                  placeholder="请输入主叫号码"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="小号号码">
                <el-input
                  v-model.trim="searchForm.telX"
                  placeholder="请输入小号号码"
                  clearable
                ></el-input>
              </el-form-item>
             
            </div>
            <el-form-item>
              <div class="flex-nowrap-flex-end">
                <el-button class="cancel" plain @click="searchTapReset" round>
                  重置
                </el-button>
                <el-button class="confirmAdd" @click="searchTapFun" round>
                  查询
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
    
    <!-- 添加弹窗 -->
    <div>
      <el-dialog
        title="添加"
        v-model="theAddDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        width="30%"
        @close="clearInputData"
      >
        <div class="">
          <el-form
            label-position="left"
            label-width="100px"
            size="small"
            :model="searchForm1"
            ref="upform"
          >
            <div class="form-out-box p-0-30">
              <el-form-item label="分配用户">
                <el-select
                  v-model="searchForm1.uid"
                  placeholder="请选择"
                  style="width: 100%"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in user_list"
                    :key="index"
                    :label="`${item.nickname || ''}--${
                      item.company || ''
                    }--(Id:${item.uid})`"
                    :value="item.uid"
                  >
                    <div class="flex-nowrap-space-between">
                      <div :class="item.status == 1 ? 'no-red' : ''">
                        <span>{{ item.nickname }}--</span>
                        <span>{{ item.company }}--</span>
                        <span class="ml-5">(Id:{{ item.uid }})</span>
                      </div>
                      <div v-if="item.status == 1" class="no-red">禁</div>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="主叫号码">
                <el-input
                  v-model.trim="searchForm1.telA"
                  placeholder="请输入主叫号码"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="小号号码">
                <el-input
                  v-model.trim="searchForm1.telX"
                  placeholder="请输入小号号码"
                  clearable
                ></el-input>
              </el-form-item>
             
            </div>
            <el-form-item>
              <div class="flex-nowrap-flex-end">
                <el-button class="cancel" plain @click="searchTapReset" round>
                  重置
                </el-button>
                <el-button class="confirmAdd" @click="addTapFun" round>
                  添加
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
     <!-- 批量添加弹窗 -->
     <div>
      <el-dialog
        title="批量添加"
        v-model="theAddAllDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        width="30%"
        @close="clearInputData"
      >
        <div class="">
          <el-form
            label-position="left"
            label-width="100px"
            size="small"
            :model="searchForm2"
            ref="upform"
          >
            <div class="form-out-box p-0-30">
              <el-form-item label="分配用户">
                <el-select
                  v-model="searchForm2.uid"
                  placeholder="请选择"
                  style="width: 100%"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in user_list"
                    :key="index"
                    :label="`${item.nickname || ''}--${
                      item.company || ''
                    }--(Id:${item.uid})`"
                    :value="item.uid"
                  >
                    <div class="flex-nowrap-space-between">
                      <div :class="item.status == 1 ? 'no-red' : ''">
                        <span>{{ item.nickname }}--</span>
                        <span>{{ item.company }}--</span>
                        <span class="ml-5">(Id:{{ item.uid }})</span>
                      </div>
                      <div v-if="item.status == 1" class="no-red">禁</div>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="号码列表">
                <el-input
                  v-model="searchForm2.number"
                  type="textarea"
                  placeholder="请输入手机号,格式以:主叫-小号"
                 :autosize="{ minRows: 6, maxRows: 8 }"
                ></el-input>
                <div>一行一个号码,格式以:主叫-小号<br> 
                  例:18856283288-18856283289</div>
              </el-form-item>
             
             
            </div>
            <el-form-item>
              <div class="flex-nowrap-flex-end">
                <el-button class="cancel" plain @click="searchTapReset" round>
                  重置
                </el-button>
                <el-button class="confirmAdd" @click="addAllTapFun" round>
                  添加
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import amount from "@/api/open/amount/amount";
import number from "@/api/open/privacy/number";
import interfacets from "@/api/open/privacy/interface";
import amountts from "@/api/open/privacy/amount";
import callts from "@/api/open/privacy/call";
import userts from "@/api/web/user";
import usernumber from "@/api/open/privacy/userNumber";
import { message } from "ant-design-vue";
import ImportFile from "@/components/importFile.vue";
import bulkImport from "@/components/bulkImport1.vue";
import { IconFont } from "@/utils/iconfont";
import dictionary from "@/api/system/dictionary";
import area from "@/api/open/area/area";
import { toRaw } from "vue";
import deepClone from "@/utils/deepClone";

export default {
  name: "Number",
  components: {
    ImportFile,
    bulkImport,
    IconFont,
  },
  data() {
    const checkNumber = (rule, value, callback) => {
      let num = 0,
        types = null,
        errorItem;
      if (value == null) {
        this.showError = false;
        callback(new Error("请填写号码 (多个号码请换行输入)"));
      } else if (value.length == 0) {
        this.showError = false;
        callback(new Error("请填写号码 (多个号码请换行输入)"));
      } else {
        let newArr = [];
        newArr = value.split(/[(\r\n)\r\n]+/);
        newArr.forEach((item, i) => {
          if (
            item.match(
              /^(1[3|4|5|6|7|8|9])\d{9}$|^0\d{2,3}-?\d{7,8}$|^9\d{9}$|^2\d{9}$|^8\d{9}$/
            ) == null
            // item.match(
            //   /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9]|9[0-9]|0[0-9][0-9]|2[0-9]|0[0-9][0-9][0-9])\d{8}$/
            // ) == null
          ) {
            // if(item.match(/(010\d{8})|(0[2-9]\d{9})|(13\d{9})|(14[57]\d{8})|(15[0-35-9]\d{8})|(18[0-35-9]\d{8})/) == null){
            // callback(new Error('请输入正确的号码 (请检查第'+(i+1)+"行)"))
            num++;
            types = i;
            errorItem = item;
          }
        });
        if (num != 0) {
          this.showError = true;
          this.errorNumber = errorItem;
          callback(
            new Error(
              "请输入正确的号码 (请检查第" +
                (types + 1) +
                "行：" +
                errorItem +
                ")"
            )
          );
        } else {
          this.showError = false;
          callback();
        }
      }
    };
    return {
      allIds: [],
      total: 0,
      tableData: [],
      searchDateTime: [],
      theQueryDialog: false, //弹窗--查询
      theAddDialog:false,//添加
      theAddAllDialog:false,
      user_list: [], // 用户的集合
      searchForm: {
        page: 1,
        pageSize: 20,
        //搜索项
        telA:'',
        telX:'',
        uid: "", //用户id
      },
      searchForm1: {   //添加
        telA:'',
        telX:'',
        uid: "", //用户id
      },
      searchForm2: {   //添加
        uid: "", //用户id
        number:''
      },
      interface_id:[],
      loading: false,
      rules: {},
    };
  },
  watch: {
 
  },
  created() {
  },
  mounted() {
    this.getNumberList();
    this.getUserList();
    this.getApiType()
  },
  computed: {},
  methods: {
    batchDelete(){

      if(this.allIds.length === 0){
        return this.$message.warning('请选择要删除的号码')
      }
      this.$confirm('是否确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        amount.ExplicitBindingdelete({ids:this.allIds}).then(res=>{
            this.$message.success('删除成功')
            this.getNumberList()
        })
      }).catch(() => {
        this.$message.info('已取消删除');
      });
    },
    Add() {
      this.theAddDialog=true
    },
    AddAll(){
      this.theAddAllDialog=true
    },
   
    handleCheckAllChange(val) {
      this.checkedPerson = val ? this.persons.map((e) => e.uid) : [];
      // console.log(val)
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.persons.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.persons.length;
    },

    // 列表多选--操作
    handleSelectionChange(val) {
      let newIds = []
      val.forEach((item) => {
        newIds.push(item.id)
      })
     // this.multipleSelection = val
      this.allIds = newIds
    },
    // 获取接口类型数组
    getApiType() {
      interfacets.seeApiList({}).then((res) => {
        this.interface_id = res.data
      })
    },
    // 获取用户列表
    getUserList() {
      userts.queryList({}).then((res) => {
        this.user_list = res.data;
      });
    },

    cancelBindNumber(formName) {
      this.$refs[formName].resetFields();
      this.centerBindVisible = false;
    },

    // 重置搜索项
    searchTapReset() {
      this.searchForm.uid = "";
      this.searchForm.telA = "";
      this.searchForm.telX=''
      this.searchForm1.uid = "";
      this.searchForm1.telA = "";
      this.searchForm1.telX='',
      this.searchForm2.uid = "",
      this.searchForm2.number = ""
    },
    // 搜索特殊项
    searchTapFun() {
      this.theQueryDialog = false;
      this.getNumberList()

    },
    addAllTapFun(){
      let upArr = []
     let arrLest = this.searchForm2.number.split(/[(\r\n)\r\n]+/)
       this.unique(arrLest).forEach((item,i) =>{
                        upArr.push(item.trim())
                    })
                   //console.log(upArr);
                   amount.ExplicitBindingAddAll({
                    uid:this.searchForm2.uid,
                    num:upArr
                   }).then((res)=>{
                      if (res.code==200) {
                        this.$message.success('添加成功')
                        this.theAddAllDialog = false;
                         this.getNumberList()
                      }else{
                        this.$message.error(res.message)
                      }
                   })
    },
    unique(arr) {
            const res = new Map();
            return arr.filter((a) => !res.has(a) && res.set(a, 1))
        },
    addTapFun() {
      amount
        .ExplicitBindingAdd({
          ...this.searchForm1,
        })
        .then((res) => {
          if (res.code==200) {
            this.$message.success('添加成功')
            this.theAddDialog = false;
            this.getNumberList()
          }else{
            this.$message.error(res.message)
          }
          
        });
        
     

    },
    clearInputData(){
      this.searchForm.uid = "";
      this.searchForm.telA = "";
      this.searchForm.telX='';
      this.searchForm1.uid = "";
      this.searchForm1.telA = "";
      this.searchForm1.telX=''
      this.searchForm2.uid = "",
      this.searchForm2.number = ""
    },
    // 获取电话池列表
    getNumberList() {
      this.loading = true;
      amount
        .ExplicitBinding({
          ...this.searchForm,
        })
        .then((res) => {
          //console.log(res,'222');
          this.tableData = res.data.records;
          this.loading = false;
          this.total = res.data.total;
        });
    },

    // 分页--条数
    handleSizeChange(val) {
      this.searchForm.pageSize = val;
      this.getNumberList();
    },
    // 分页--页码
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.currentPage = val;
      this.getNumberList();
    },
  },
};
</script>
<style lang="scss" scoped>
.number {
  padding-right: 10px;
}

.showIdsList {
  min-height: 30px;
  max-height: 260px;
  overflow-y: overlay;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding-left: 16px;
}

.title-buttom {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}

.cancel {
  color: #637dff;
  border: #637dff solid 1px;
}

.confirmAdd {
  background: #637dff;
  color: #fff;
}

.form-out-box {
  height: 400px;
  margin-bottom: 20px;
  overflow-y: overlay;
}

.yes-green-span {
  display: inline-block;
  height: 20px;
  padding: 0 5px;
  line-height: 19px;
  color: #409eff;
  background: #ecf5ff;
  border: 1px solid #ecf5ff;
}

.no-red-span {
  display: inline-block;
  height: 20px;
  padding: 0 5px;
  line-height: 19px;
  background: #f4f4f5;
  border: 1px solid #f4f4f5;
}

.redItem {
  color: #f0142f;
}

.showErrList {
  height: 200px;
  overflow: scroll;
  width: 250px;
}

::v-deep(.checkbox .el-checkbox) {
  display: block;
}

.noticetext span {
  color: #f0142f;
}
</style>
